import React from 'react'
import Head from 'flareact/head'

import '../styles/styles.css'

export default function App ({ Component, pageProps }) {
  return (
    <div className="h-screen bg-gradient-to-r from-purple-400 via-pink-500 to-red-500">
      <Head>
        <meta charSet="utf-8" />
        <meta property="og:site_name" content="~~ mental vacations ~~" key="ogsitename" />
        <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
        <link rel="manifest" href="/site.webmanifest" />
      </Head>
      <Component {...pageProps} />
    </div>
  )
}
