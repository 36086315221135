import React from 'react'
import Head from 'flareact/head'

import Layout from '../components/Layout'

export default function Index () {
  return (
    <Layout>
      <Head>
        <title>
        ~~ mental.vacations ~~
        </title>
        <meta name="description" content="brain is taking a break"></meta>
        <meta property="og:url" content="https://mental.vacations" key="ogurl" />
        <meta property="og:title" content="~~ mental.vacations ~~" key="ogtitle" />
        <meta property="og:description" content="brain is taking a break" key="ogdesc" />
      </Head>
      <div className="prose m-auto">
        <h1>
        🏝 relax 🏝
        </h1>
        <p>let&rsquo;s do some fun things</p>
        <p>have a ball, babe</p>
      </div>
    </Layout>
  )
}
